<rs-top-bar-menu
  [application]="title"
  [cdnUrl]="topBarCdn"
  [dummy]="topBarEnvironment === 'dev'"
  [environment]="topBarEnvironment"
  [footer]="false"
></rs-top-bar-menu>

<rs-loader></rs-loader>

<router-outlet [attr.data-app-version]="appVersion"></router-outlet>

<rs-footer />